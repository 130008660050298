import Layout3 from '@/components/layouts/Layout3.vue';
import SiteHeader from '@/components/SiteHeader.vue';
export default defineComponent({
  components: {
    Layout3: Layout3,
    SiteHeader: SiteHeader
  },
  data: function data() {
    return {
      region: ''
    };
  },
  head: function head() {
    return {
      title: 'Profile - Signature Standards - Legislation'
    };
  },
  methods: {
    goToSelectedIdentification: function goToSelectedIdentification() {
      if (this.region === 'EU') {
        void this.$router.push({
          name: 'profile-signature-standards-eidas'
        });
      } else if (this.region === 'CH') {
        void this.$router.push({
          name: 'profile-signature-standards-zertes'
        });
      }
    }
  }
});